const menuItems = {
    items: [
        {
            id: 'navigation',
            languageKey: 'COMMON.Navigation',
            title: 'Navigation',
            type: 'group',
            applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'home',
                    languageKey: 'COMMON.Home',
                    title: 'Home',
                    type: 'collapse',
                    applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                    screenID: '',
                    icon: 'feather icon-home',
                    children: [
                        //{
                        //    id: 'default',
                        //    languageKey: 'COMMON.Default',
                        //    title: 'Default',
                        //    type: 'item',
                        //    applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                        //    screenID: '0c69f181-2438-4e09-bce9-022e1de6ad03',
                        //    url: '/app/home/dashboard'
                        //},
                        //{
                        //    id: 'tasks_board',
                        //    languageKey: 'COMMON.Task Board',
                        //    title: 'Task Board',
                        //    type: 'item',
                        //    applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                        //    screenID: '483fec51-ca96-43ae-82ce-2588133db156',
                        //    url: '/app/home/task_board'
                        //},
                        {
                            id: 'calendar',
                            languageKey: 'COMMON.Calendar',
                            title: 'Calendar',
                            type: 'item',
                            applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                            screenID: '85efa8b5-8566-45aa-9fad-8ad8d57fc0cf',
                            url: '/app/home/calendar'
                        }
                    ]
                }
            ]
        },
        {
            id: 'incident_reporting_admin',
            languageKey: 'COMMON.Incident Reporting Admin',
            title: 'Incident Reporting Admin',
            type: 'group',
            applicationID: '94498c60-7495-4260-a671-3f9352b46d06',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'incident_reporting_admin',
                    languageKey: 'COMMON.Incident Reporting Admin',
                    title: 'Incident Reporting Admin',
                    type: 'collapse',
                    applicationID: '94498c60-7495-4260-a671-3f9352b46d06',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'library_management',
                            languageKey: 'IRADMIN.Library Management',
                            title: 'Library Management',
                            type: 'item',
                            applicationID: '94498c60-7495-4260-a671-3f9352b46d06',
                            screenID: '37beb310-fb10-44de-aa82-0736c1175620',
                            url: '/app/ir_admin/library_management'
                        }
                    ]
                }
            ]
        },
        {
            id: 'incident_reporting_user',
            languageKey: 'COMMON.Incident Reporting',
            title: 'Incident Reporting',
            type: 'group',
            applicationID: '',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'incident_reporting_user',
                    languageKey: 'COMMON.Incident Reporting',
                    title: 'Incident Reporting',
                    type: 'collapse',
                    applicationID: '',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'incident_reporting',
                            languageKey: 'IR.Incident Reporting',
                            title: 'Incident Reporting',
                            type: 'item',
                            applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                            screenID: '',
                            url: '/app/incident_reporting_user/incident_reporting'
                        }
                    ]
                }
            ]
        },
        {
            id: 'report',
            languageKey: 'COMMON.Report',
            title: 'Report',
            type: 'group',
            applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'report',
                    languageKey: 'COMMON.Report',
                    title: 'Report',
                    type: 'collapse',
                    applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'report',
                            languageKey: 'COMMON.Report',
                            title: 'Report',
                            type: 'item',
                            applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                            screenID: 'a101c9a0-f71f-457f-a771-812c34a45e43',
                            url: '/app/ir_report/report'
                        },
                        {
                            id: 'report_query',
                            languageKey: 'COMMON.Report Query',
                            title: 'Report Query',
                            type: 'item',
                            applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                            screenID: '8f375f3a-8873-42cc-b0ee-96a676426341',
                            url: '/app/ir_report/report_query'
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
