import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";
import AccessRightsGuard from "./components/Auth/AccessRightsGuard";

import CustomSecurityEncrypt from "./components/Security/CustomSecurityEncrypt";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    <CustomSecurityEncrypt {...props}>
                                        {route.routes
                                            ? renderRoutes(route.routes)
                                            : <Component {...props}
                                                applicationID={route.applicationID}
                                                screenID={route.screenID} />}
                                    </CustomSecurityEncrypt>
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Auth/Signin/SignIn'))
    },
    {
        exact: true,
        path: '/404',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Errors/NotFound404'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            //{
            //    exact: true,
            //    guard: AccessRightsGuard,
            //    path: '/app/home/dashboard',
            //    applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
            //    screenID: '0c69f181-2438-4e09-bce9-022e1de6ad03',
            //    component: lazy(() => import('./views/Home/Dashboard/DashDefault'))
            //},
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/user_profile',
                applicationID: '',
                screenID: '',
                component: lazy(() => import('./views/Common/User_Profile'))
            },
            //{
            //    exact: true,
            //    guard: AccessRightsGuard,
            //    path: '/app/home/task_board',
            //    applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
            //    screenID: '483fec51-ca96-43ae-82ce-2588133db156',
            //    component: lazy(() => import('./views/Home/Task_Board/TaskBoard'))
            //},
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/calendar',
                applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                screenID: '85efa8b5-8566-45aa-9fad-8ad8d57fc0cf',
                component: lazy(() => import('./views/Home/Calendar/Calendar'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/incident_reporting_user/incident_reporting',
                applicationID: '',
                screenID: '',
                component: lazy(() => import('./views/IR/IR_User/IR'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/incident_reporting_user/incident_reporting_form',
                applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                screenID: '',
                component: lazy(() => import('./views/IR/IR_User/IR_Form'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/ir_admin/library_management',
                applicationID: '94498c60-7495-4260-a671-3f9352b46d06',
                screenID: '37beb310-fb10-44de-aa82-0736c1175620',
                component: lazy(() => import('./views/IR/IR_Admin/Library_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/ir_report/report',
                applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                screenID: 'a101c9a0-f71f-457f-a771-812c34a45e43',
                component: lazy(() => import('./views/IR/IR_Report/Report'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/ir_report/report_query',
                applicationID: '52e0f77f-6163-4384-933e-3d5ab5a0a32d',
                screenID: '8f375f3a-8873-42cc-b0ee-96a676426341',
                component: lazy(() => import('./views/IR/IR_Report/Report_Query'))
            },
            {
                path: '*',
                exact: true,
                applicationID: '',
                screenID: '',
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;
